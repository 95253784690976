import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'

const TermConditionsPage = () => {
  return (
    <Layout>
      <SEO
        title="Term and Conditions"
        description="...."
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa Massage chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa Massage chairs for sale,Cheap spa pedicure chairs,pedicure spa Massage chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container py-4">
        <div className="row py-5">
          <div className="col">
            <h1 className="w-100 text-center">PURCHASE TERMS AND CONDITIONS</h1>
          </div>
        </div>
        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <p>
              This section sets forth the Purchase Terms and Conditions of sale of CONTÉGO Spa
              Designs, Inc. (“Contego”, “we”, “us” or “our”). We manufacture, sell and service
              Pedicure Spa Massage Chairs and accessories (“products”) displayed at{' '}
              <a href="https://www.contegospa.com" target="_blank" rel="noopener noreferrer">
                www.contegospa.com
              </a>{' '}
              (our “website”). By completing a purchase with us you, our customer (&quot;you&quot;,
              &quot;your&quot; or &quot;Customer&quot;), agree to these Purchase Terms and
              Conditions and to be bound by them. These Purchase Terms and Conditions include the
              terms of all documents and terms referenced herein (collectively, this “Agreement”)
              and govern all purchases and transactions you make with us. No terms of any document
              or form furnished by you shall alter, supplement or contradict any of the terms of
              this Agreement.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>LIMITED WARRANTY</h3>

            <p>
              The only warranties provided by Contego are expressly set forth in the Limited
              Warranty (displayed at{' '}
              <a
                href="https://www.contegospa.com/limitedwarranty"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.contegospa.com/limitedwarranty
              </a>{' '}
              and incorporated herein) in effect on the date of purchase, which you acknowledge you
              have reviewed. We disclaim all conditions, warranties, and guarantees that are not
              expressly set forth therein and any implied conditions, warranties, and/or guarantees,
              including, absent limitation, implies warranties of merchantability and fitness for a
              particular use. Except as provided in our Limited Warranty, all products are sold “as
              is.” Customer is responsible for proper maintenance of purchased products; see
              “maintenance” section of our user manual accessible on our Website. Please note: the
              Contego Jet-Liner is a one-time-use product. It must be disposed of immediately after
              use to ensure prevention of contamination or infection.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>GENERAL TERMS</h3>

            <p>
              We carry the products displayed on our website. In some cases, however, products on
              our website may be out of stock or on back order or may be discontinued without
              notice. All items shown on our website are only available from us.
            </p>
            <p>
              Every effort has been made to ensure the accuracy of prices, item numbers,
              availability, and dimensions; however, we cannot be responsible for typographical
              errors. We reserve the right to revoke any stated offer and to correct any errors,
              inaccuracies, or omissions even after an order has been submitted or the order has
              been confirmed and your credit card charged or payment is made by any other method.
            </p>
            <p>
              Some of our products have been photographed with other items. Those items are not
              included with product purchase.
            </p>
            <p>
              All prices and services featured on our website are quoted in U.S. dollars, are valid
              and effective only in the continental U.S.
            </p>
            <p>
              The total order amount, including sales tax and shipping and delivery charges, must be
              made in full prior to shipment, and fifty percent (50%) of said amount (the
              &quot;Deposit&quot;) must be made at the time the order is placed. Purchases made with
              a credit card are subject to a 3% surcharge.
            </p>
          </div>
        </div>

        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>SHIPPING COSTS/STORAGE FEES</h3>

            <p>
              We ship all products FOB shipping point. Customer is responsible for shipping costs,
              as well as for damages incurred during shipment. If any ordered product is not
              available to ship by the estimated shipping date stated on your initial product order
              (“Original Shipping Date”), we may extend the Original Shipping Date to ninety (90)
              days from the date we notify you such product is available to ship (the “Extended
              Shipping Date”); it being understood and agreed that we may charge storage fees if you
              request shipment to occur after the Extended Shipping Date. Customer may not request
              shipment to occur more than six (6) months after the Extended Shipping Date (the
              “Maximum Deferral Date”). Customer must pay all storage fees due (if any) prior to
              shipment.
            </p>
          </div>
        </div>

        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>FAILURE TO TAKE DELIVERY BEFORE MAXIMUM DEFERRAL DATE</h3>

            <p>
              Customer acknowledges that its obligation to take shipment of product on or before the
              Maximum Deferral Date is a material term of this Agreement. Customer agrees with us
              that if Customer does not take shipment of product on or before the Maximum Deferral
              Date, our damages would be material, uncertain and not readily ascertainable.
              Accordingly, Contego and Customer agree that if Customer does not take shipment of
              product on or before the Maximum Deferral Date, then we may keep the amount of the
              Deposit as and for liquidated damages (the &quot;Liquidated Damages&quot;). Customer
              agrees that the Liquidated Damages are reasonable in proportion to the probable
              damages likely to be sustained by Contego if Customer does not take shipment of its
              product on or before the Maximum Deferral Date, that the amount of actual damages
              Contego may sustain in such event is incapable of precise estimation, and that
              retention of the Deposit as Liquidated Damages is not intended to constitute a penalty
              for any purposes.
            </p>
          </div>
        </div>

        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>INSPECTION/ACCEPTANCE</h3>

            <p>
              Promptly upon its receipt of a shipment of products, Customer shall examine the
              shipment to determine whether any item or items included in the shipment are in short
              supply, defective or damaged. Within 48 hours of receipt of the shipment, Customer
              shall notify Contego in writing of any shortages, defects or damage, which Customer
              claims existed at the time of delivery. Within 30 days after the receipt of such
              notice, Contego will investigate the claim of shortages, defects or damage, inform
              Customer of its findings, and deliver to Customer products to replace any which
              Contego determines, were in short supply, defective or damaged at the time of
              delivery. Unless notice is given as provided in this paragraph, Customer shall be
              deemed to have accepted such products and to have waived all claims for shortages,
              defect or damage.
            </p>
          </div>
        </div>

        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>PEDICURE SPA CHAIR DELIVERY AND HOOK-UP SERVICE </h3>
            <p>
              If you purchase our pedicure spa chair(s), and we deliver them, we will connect them
              to water and electricity inside your salon pursuant to the terms and conditions stated
              in our Deliver and Hook-Up Service (displayed at{' '}
              <a
                href="https://www.contegospa.com/deliveryandhook-upservice"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.contegospa.com/deliveryandhook-upservice
              </a>{' '}
              and incorporated herein). However, if we ship your pedicure spa chair(s) using a
              freight company, you are responsible to connect them to water and electricity inside
              your salon according to the terms of our Shipping Terms (displayed at{' '}
              <a
                href="https://www.contegospa.com/shippingterms"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.contegospa.com/shippingterms
              </a>{' '}
              and incorporated herein), which you hereby agree to prior to shipment.
            </p>
          </div>
        </div>

        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>ORDER CANCELLATIONS</h3>

            <p>We cannot accept order cancellations on any items after order placement.</p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>RETURNS</h3>

            <p>We do not accept returns. We do not accept refunds.</p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>CONTENT TO USE</h3>

            <p>
              Customer hereby authorizes Contego and its agents to use phone numbers and email
              addresses provided by Customer for order status updates, delivery scheduling,
              marketing, and surveys, including automated calls and text messages, in accordance
              with our Privacy Policy found on our Website (displayed at{' '}
              <a
                href="https://www.contegospa.com/privacypolicy"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.contegospa.com/privacypolicy
              </a>{' '}
              incorporated herein).
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>CHANGES TO PURCHASE TERMS AND CONDITIONS</h3>

            <p>
              We may update or modify these Purchase Terms and Conditions at any time and without
              prior notice. For this reason, you should review our Purchase Terms and Conditions
              each time you purchase products from us.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>TRADEMARKS and COPYRIGHTS</h3>

            <p>
              Certain names and marks are proprietary and are owned by Contego Spa Designs, Inc.
              “Contego” is a registered trademark of Contego Spa Designs, Inc. This website and all
              its contents are protected under copyright by Contego Spa Designs, Inc. with all
              rights reserved.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>STATE OF CALIFORNIA PROPOSITION 65 WARNING</h3>

            <p>
              Products may contain one or more chemicals known to the State of California to cause
              cancer or reproductive harm.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>INCORPORATION BY REFERENCE</h3>

            <p>
              Each of our policies referenced herein is incorporated herein by reference and may be
              added to, amended, changed, revised, and/or modified by Contego, from time to time, at
              our sole and absolute discretion, and we will provide notice of such changes if and as
              required by law.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>CONSENT TO COMMUNICATIONS FROM CONTEGO</h3>

            <p>
              By purchasing any of our products, you agree we may use your contact information
              (including your name, mailing address, email address and telephone number) to receive
              electronic and print marketing and promotional materials and other communications from
              us, including by means of social media (“Communications”).
            </p>

            <p>
              Our Privacy Policy describes in greater detail the personal information we collect
              about you, how we use that personal information, and your rights with respect to it.
              Your acceptance of the Privacy Policy, together with your agreement to each and every
              one of these Purchase Terms and Conditions and this Agreement is a prerequisite to
              purchasing any of our products.
            </p>

            <p>
              To the extent that the laws of any applicable jurisdiction limit or prohibit our
              ability to send Communications to you without additional steps having been taken, the
              foregoing shall not be deemed to apply to you until such additional legally required
              steps have been taken.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>MARKETING AND EMAIL SERVICES</h3>

            <p>
              Unless you expressly opt out, to the extent permitted by applicable law, you agree
              that we may, in accord with our Privacy Policy, provide, transmit, deliver, and/or
              share Communications, Contact Information, credit or debit card information, and other
              personally identifiable information with individuals and/or entities which provide
              services to us including, business services such as email management, catalog and
              address management, and promotional service. Personal information (as defined in our
              Privacy Policy) may be accessed/processed by the software and/or hardware of the
              foregoing service providers and/or may be kept on a remote server, belonging to a
              third-party service provider, that is designated to store such information
              electronically. We also maintain your name, contact information, and other personal
              information on our internal corporate systems and databases.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>OBTAINING A PRINTED COPY</h3>

            <p>
              You may obtain a printed copy of the current Purchase Terms and Conditions by sending
              an email to{' '}
              <a href="mailto:contact@contegospa.com" target="_blank" rel="noopener noreferrer">
                contact@contegospa.com
              </a>{' '}
              and requesting a copy to be sent to you by mail and providing your Contact Information
              in the email. Alternatively, if you would like an emailed copy of these Purchase Terms
              and Conditions, please provide your name and the email address to which you would like
              for the Purchase Terms and Conditions to be sent.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>THE UNITED STATES ELECTRONIC SIGNATURES IN GLOBAL AND NATIONAL COMMERCE ACT</h3>

            <p>
              You acknowledge and agree that your consent to these Purchase Terms and Conditions,
              and all other representations, acknowledgements, and agreements stated herein, are
              subject to and shall be deemed to be in compliance with the U.S. Electronic Signatures
              in Global and National Commerce Act (the &ldquo;Act&ldquo;). You and Contego each
              intend for the Act to apply to the fullest extent possible to validate Contego’s
              ability to conduct business with, communicate with, and send offers, and promotional
              materials, and other information and content permitted by these Purchase Terms and
              Conditions to you by electronic means and otherwise, and you grant consent for all
              such electronic transmissions.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>LIMITATIONS OF LIABILITY</h3>

            <p>
              To the fullest extent permitted under applicable law, IN NO EVENT SHALL CONTEGO SPA
              DESIGNS, INC. BE LIABLE TO CUSTOMER, WHETHER UNDER THEORY OF CONTRACT, TORT (INCLUDING
              NEGLIGENCE), OR OTHERWISE, FOR ANY INDIRECT, INCIDENTAL, PUNITIVE, CONSEQUENTIAL, OR
              SPECIAL DAMAGES (INCLUDING ANY DAMAGE TO BUSINESS REPUTATION, LOST PROFITS, OR LOST
              DATA), WHETHER OR NOT FORESEEABLE OR ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>MAXIMUM LIABILITY</h3>

            <p>
              In addition, you agree that the maximum collective liability of Contego for all
              claims, causes of action, losses, or damages, however arising and by way of whatever
              type of proceeding, shall be no greater than one hundred dollars ($100). Except as is
              otherwise prohibited by applicable law, the limitations set forth herein shall apply
              to any theory of legal liability, including, among all others, breach or repudiation
              of contract, violation of statute, violation of common law, tort, negligence, gross
              negligence, recklessness, intentional misconduct, strict liability, or any other claim
              or damage for any type of civil liability, or based upon any other legal theory and
              irrespective of whether damages, actual or otherwise, are deemed to exist.
            </p>

            <p>
              To the fullest extent permitted under applicable law, by purchasing one or more
              products from Contego and agreeing to these terms and conditions, you voluntarily
              agree that you have relinquished your right to seek damages from Contego as and to the
              extent stated herein and that the agreement to accept such limitations reflects a
              reasonable allocation of risk.
            </p>

            <p>
              The provisions of the foregoing paragraphs that impose a damages limitation of one
              hundred dollars ($100) or otherwise prohibit damages to be multiplied or increased, do
              not apply in those states, including without limitation to consumers within the state
              of New Jersey or other states of the United States which do not allow the exclusion of
              certain warranties or the limitation or exclusion of liability for incidental,
              consequential, or another type of damages.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>LEGAL DISPUTES</h3>

            <p>
              Please read this section carefully, as these provisions provide for the resolution of
              disputes through the use of binding arbitration on an individual basis, and also
              include certain waivers, including waivers of all class actions, class arbitrations,
              jury trials, and the right to proceed in court (other than arbitration).
            </p>
            <p>
              You and Contego agree that any dispute or claim relating to any transaction or
              interaction with Contego (including, but not limited to every purchase), these
              Purchase Terms and Conditions, and/or any other aspect or issue concerning the
              relationship and transactions between you and Contego, whether pertaining to contract,
              tort, statute, common law, fraud, misrepresentation, civil liability or any other
              legal theory (each, a &quot;Dispute&quot;) will be resolved in accordance with the
              provisions in this Legal Disputes section and any other applicable Purchase Terms and
              Conditions.
            </p>
            <p>
              Subject to applicable law, these Purchase Terms and Conditions and any Dispute arising
              from or in connection thereto shall be governed by the laws of the United States,
              including the Federal Arbitration Act, and the laws of the State of California,
              without regard to principles of conflicts of law. To the extent prohibited by
              applicable law, non-U.S. citizens agree to an alternative procedure for dispute
              resolution if the arbitrator rules that arbitration is prohibited by law.
            </p>
            <p>
              Except for disputes that qualify for small claims courts, all disputes arising out of
              or related to these Purchase Terms and Conditions or any aspect of the relationship
              between you and Contego, whether based in contract, tort, statute fraud,
              misrepresentation or any other legal theory, will be resolved through final and
              binding arbitration as follows, and you agree that Contego and you are each
              voluntarily surrendering and waiving the right to trial by a judge or a jury. You
              agree that any arbitration under these terms and conditions will take place on an
              individual basis. Class arbitration and class actions are not permitted and you are
              expressly agreeing to give up the ability to participate in a class action.
            </p>
            <p>
              Any Disputes shall be submitted to binding arbitration with the Judicial Arbitration &
              Mediation Service, Inc., at its Orange County, California office. The terms and
              procedures for such arbitration shall be as follows. If we are unable to agree on a
              single retired judge from the JAMS panel, JAMS will provide a list of three available
              judges and each Party may strike one. The remaining judge will serve as the
              arbitrator. Arbitration must be initiated within one (1) year after the claimed breach
              occurred. Failure to initiate arbitration within that period constitutes an absolute
              bar to the institution of any new proceedings. Arbitration is initiated by sending
              written notice of an intention to arbitrate by registered or certified mail to all
              Parties and to JAMS. The notice must contain a description of the dispute, the amount
              involved, and the remedy sought. The arbitrator shall determine the rights, rules and
              procedures for the Parties to follow. Both Parties shall have the right to confirm,
              correct or vacate the arbitration award, pursuant to California Code of Civil
              Procedure, Sections 1285, et seq. The sole and exclusive venue to confirm, correct or
              vacate the arbitration award shall be the Superior Court of California for the County
              of Orange, Central Justice Center, located at 700 Civic Center Drive West, Santa Ana,
              California 92702.
            </p>
            <p>
              To the extent you prevail on the Dispute(s) that are subject to the arbitration, we
              agree to pay up to, and you agree that you will not seek recovery from us of more
              than, the total sum of $5,000 for attorney&apos;s fees, costs, and expenses associated
              with the Dispute and the arbitration thereof; provided, however, that in the event you
              prevail, we also agree that we will pay the costs of the arbitrator&apos;s fees and
              the other costs associated with the arbitration itself. Likewise, even if we prevail,
              we will not seek attorneys&apos; fees, costs, and expenses associated with the Dispute
              exceeding $5,000. You agree that if the amount involved in the Dispute is less than
              $10,000, the arbitration will be conducted, at your election, by telephone, based on
              written submissions, or in person in a mutually agreed location.
            </p>
            <p>
              The arbitrator&apos;s decision, which shall be consistent with these Purchase Terms
              and Conditions, will be final and binding. The arbitrator will have authority to award
              temporary, interim, or permanent injunctive relief and/or relief providing for
              specific performance of these Purchase Terms and Conditions, but only to the extent
              necessary to provide relief warranted by individual claims before the arbitrator. The
              award rendered by the arbitrator may be confirmed and enforced by any court having
              jurisdiction thereof. Notwithstanding any of the foregoing, nothing in these Purchase
              Terms and Conditions will preclude you from bringing issues to the attention of
              federal, state, territorial, provincial, or local governments or agencies and, if and
              to the extent applicable law permits, such entities having jurisdiction may seek
              relief.
            </p>
          </div>
        </div>
        <div className="row flex-column rounded py-2 mb-2">
          <div className="col">
            <h3>MISCELLANEOUS</h3>

            <p>
              This Agreement contains the entire agreement and understanding by and between you and
              Contego with respect to the subject matter hereof, and no representations, promises,
              agreements or understandings, written or oral, not herein contained shall be of any
              force or effect. No change or modification hereof shall be valid or binding unless the
              same is in writing and signed by the party intended to be bound. This Agreement shall
              be binding upon, and shall inure to the benefit of, Contego and you, and their
              respective successors and/or assigns. However, you may not assign this agreement or
              any duties hereunder without the prior express written authorization of Contego. The
              provisions of this Agreement shall be deemed severable, and the invalidity or
              unenforceability of any one or more of the provisions hereof shall not affect the
              validity and enforceability of the other provisions hereof. The headings and other
              captions in this Agreement are for convenience and reference only and shall not be
              used in interpreting, construing or enforcing any of the provisions of this Agreement.
              Neither party shall be liable for the failure to perform its obligations under this
              Agreement due to events beyond such party’s reasonable control including, but not
              limited to, strikes, riots, wars, fire, acts of God, or acts in compliance with any
              applicable law, regulation, or order (whether valid or invalid) of any court or
              governmental body. No waiver of any provision of this Agreement shall be valid unless
              the same is in writing and signed by the party against whom such waiver is sought to
              be enforced; moreover, no valid waiver of any provision of this Agreement at any time
              shall be deemed a waiver of any other provision of this Agreement at such time or
              shall be deemed a valid waiver of such provision at any other time.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TermConditionsPage
